import {gsap} from "gsap";

function animate_masthead_letters(heading) {
    let timeline = gsap.timeline();
    // Grab each splitted word
    let $letters = $(heading).find('.title-chunk span');

    // Apply animation to splitted word
    timeline.set($letters, {
        yPercent: 125,
        rotate: 0.001,
        opacity: 0
    });

    // Apply animation to splitted word
    timeline.to($letters, 1, {
        opacity: 1,
        ease: 'Expo.easeOut',
        yPercent: 0,
        rotate: 0.001,
        stagger: {
            amount: 0.025
        }
    }, '<');
}

function split_masthead_heading(heading) {
    // Split heading text by space into words
    let words = heading.text().split(' ');

    // Reset the heading
    heading.text('');
    heading.css('visibility', 'visible');

    // Apply splitted words to the heading
    $.each(words, function(index, word) {
        heading.append('<div class="title-chunk"><span>' + word + "&nbsp;</span></div>");
    });
}

function animate_masthead_heading(block) {
    // Loop through each instance
    block.each(function(i, e) {
        // Grab all headings
        let headings = $(e).find('.mh-copy h1');

        // Grab the first child to apply the animation to
        let heading = headings.eq(0);

        // Split the masthead heading text to be animate each individual word
        split_masthead_heading(heading);

        // Animate the masthead heading text
        animate_masthead_letters(heading);

        // Show heading
        headings.css('display', 'block');
    });
}

$(() => {
    let $masthead = $('mimas-masthead');

    // Check if masthead exists
    if($masthead.length) {
        animate_masthead_heading($masthead);
    }
});